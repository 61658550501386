<template>
  <div class="vx-row mt-5">
    <div
      class="vx-col w-full sm:w-1/2 md:w-1/3 mb-5"
      v-for="(item, index) in masters"
      :key="index"
    >
      <router-link :to="item.path">
        <ProjectCard
          v-if="loginUserRole == 'Admin'"
          :icon="item.icon"
          type="area"
          :titleName="item.title"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import ProjectCard from "@/components/project-cards/projectCard.vue";
export default {
  components: {
    ProjectCard,
  },

  data() {
    return {
      loginUserRole: window.localStorage.getItem("UserRole"),
      masters: [
        { title: "Company", icon: "BriefcaseIcon", path: "/master/company" },
        {
          title: "Department",
          icon: "UsersIcon",
          path: "/master/DepartmentView",
        },
        { title: "Category", icon: "ListIcon", path: "/master/category" },
        {
          title: "Designation",
          icon: "PenToolIcon",
          path: "/master/Designation",
        },
        {
          title: "Set Name",
          icon: "VideoIcon",
          path: "/master/shoot-location",
        },

        {
          title: "Employment",
          icon: "UsersIcon",
          path: "/master/Employment",
        },
        // { title: "Add Project", icon: "PlusCircleIcon", path: "/projects" },
        { title: "Country", icon: "FlagIcon", path: "/master/country" },
        { title: "Currency", icon: "DollarSignIcon", path: "/master/Currency" },
        { title: "GST", icon: "PercentIcon", path: "/master/gst" },
        { title: "TDS", icon: "PercentIcon", path: "/master/tds" },
        {
          title: "Payment Mode",
          icon: "GlobeIcon",
          path: "/master/paymentMode",
        },
        {
          title: "Priority",
          icon: "PlusIcon",
          path: "/master/priority",
        },
        { title: "City", icon: "HomeIcon", path: "/master/city" },
      ],
    };
  },
};
</script>

<style></style>
